import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/enviroment';
import { IExchangeDetails, IExchangesResponse, IExchangesStatus, IStatus } from '../interfaces/exchanges';

@Injectable({
  providedIn: 'root',
})
export class ExchangesService {
  public status: BehaviorSubject<any> = new BehaviorSubject(null);
  public userToken = sessionStorage.getItem('adminUserToken');
  private _HttpClient = inject(HttpClient);

  public FilterStatus = [
    { status: 'Completed' },
    { status: 'Processing' },
    { status: 'Pending' },
    { status: 'On Hold' },
    { status: 'Refunded' },
    { status: 'Returned' },
    { status: 'Canceled' },
    { status: 'Paid' },
    { status: 'Failed' },
  ];
  constructor() {
    this.subscribeOnGetStatusOfExchanges();
  }

  getExchanges(page: number, limit?: number, userId?: string, orderId?: string, email?: string, send?: string, receive?: string, statue?: string): Observable<IExchangesResponse> {
    const optionalParams = [];

    if (userId) optionalParams.push(`&userId=${userId}`);
    if (orderId) optionalParams.push(`&orderId=${orderId}`);
    if (email) optionalParams.push(`&email=${email}`);
    if (send) optionalParams.push(`&sender=${send}`);
    if (receive) optionalParams.push(`&receiver=${receive}`);
    if (limit) optionalParams.push(`&limit=${limit}`);
    if (statue == 'onHold') statue = 'ON_HOLD';
    if (statue) optionalParams.push(`&status=${statue.toUpperCase()}`);

    return this._HttpClient.get<IExchangesResponse>(`${environment.apiUrl}/v1/order/admin-orders?page=${page}${optionalParams.join('').split(',').toString()}`);
  }

  getExchangeDetails(id: string): Observable<IExchangeDetails> {
    return this._HttpClient.get<IExchangeDetails>(`${environment.apiUrl}/v1/order/order-details/${id}`);
  }

  getExchangesStatus(id: string): Observable<IExchangesStatus> {
    return this._HttpClient.get<IExchangesStatus>(`${environment.apiUrl}/v1/order/user-orders-stats/${id}`);
  }

  changeExchangeStatus(id: string, status: string): Observable<any> {
    if (status == 'On Hold') status = 'on_hold';
    return this._HttpClient.patch(`${environment.apiUrl}/v1/order/change-status/${id}/${status.toUpperCase()}`, {});
  }

  getUserExchanges(id: string, page: number, limit: number, orderId?: string, send?: string, receive?: string, status?: string): Observable<IExchangesResponse> {
    console.log('status: ', status);
    const optionalParams = [];

    if (orderId) optionalParams.push(`&orderId=${orderId}`);
    if (send) optionalParams.push(`&sender=${send}`);
    if (receive) optionalParams.push(`&receiver=${receive}`);
    if (status == 'ON HOLD') status = 'ON_HOLD';
    if (status) optionalParams.push(`&status=${status}`);

    return this._HttpClient.get<IExchangesResponse>(`${environment.apiUrl}/v1/order/admin-user-orders/${id}?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`);
  }

  subscribeOnGetStatusOfExchanges() {
    this.status.subscribe(res => {
      if (!res) this.getStatusOfExchanges().subscribe({
        next: (res) => {
          this.status.next(res);
        },
      });
    })
  }

  getStatusOfExchanges(): Observable<IStatus> {
    return this._HttpClient.get<IStatus>(`${environment.apiUrl}/v1/order/admin-orders-stats`);
  }

  getRedeemCode(id: string, code: string): Observable<{message: string, code: string}> {
    return this._HttpClient.patch<{message: string, code: string}>(`${environment.apiUrl}/v1/order/assign-code/${id}`, {code});
  }
}
